import * as THREE from "three";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils.js";

import * as YUKA from "yuka";
import gsap from "gsap";
import ScrollTrigger from "gsap/src/ScrollTrigger";

let updateCount = 0;

const clock = new THREE.Clock();

const time = new YUKA.Time();

// blender models
const robot = new URL("../assets/meshkh.glb", import.meta.url);
const ladder = new URL("../assets/newladderrrr.glb", import.meta.url);

//canvas
const renderer = new THREE.WebGLRenderer({
  alpha: true,
  antialias: true,
  powerPreference: "high-performance",
});

const app = document.getElementById("app");
renderer.setSize(app.offsetWidth, app.offsetHeight);
renderer.setPixelRatio(window.devicePixelRatio);

app.appendChild(renderer.domElement);

//scene
const scene = new THREE.Scene();
//scene.background = new THREE.Color(0x1a1a1a);

//camera
const camera = new THREE.PerspectiveCamera(
  45,
  app.offsetWidth / app.offsetHeight,
  0.1,
  1000
);

camera.position.set(0, 8, 158);

//axes

//const axesHelper = new THREE.AxesHelper(5);
//scene.add(axesHelper);

//orbit

//const orbit = new OrbitControls(camera, renderer.domElement);

//lights

const ambientLight = new THREE.AmbientLight(0xffffff, 150);
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight(0xffffff, 27);
scene.add(directionalLight);
directionalLight.position.set(3, -10, 111);

const directionalLight1 = new THREE.DirectionalLight(0xffffff, 27);
scene.add(directionalLight1);
directionalLight.position.set(10, -8, 71);

//const dLightHealper = new THREE.DirectionalLightHelper(directionalLight1);
//scene.add(dLightHealper);

////////////////////////////////////////////////////////////////////////////////////////////

const boxRenderer = new THREE.WebGLRenderer({
  antialias: true,
  antialias: false,
  powerPreference: "high-performance",
});
const boxContainer = document.getElementById("boxex");
boxRenderer.setSize(boxContainer.offsetWidth, boxContainer.offsetHeight);
boxRenderer.setPixelRatio(window.devicePixelRatio);
boxContainer.appendChild(boxRenderer.domElement);
//const loader = new THREE.TextureLoader();
const textureURL = new URL("../assets/light.jpg", import.meta.url);
const textureURL2 = new URL("../assets/earthb.jpg", import.meta.url);
const textureURL3 = new URL("../assets/earthc.jpg", import.meta.url);

const boxScene = new THREE.Scene();
boxScene.background = new THREE.Color(0x080820);

const boxCamera = new THREE.PerspectiveCamera(
  45,
  boxContainer.offsetWidth / boxContainer.offsetHeight,
  0.1,
  1000
);
boxCamera.position.set(0, 0, 4.5);
// const orbit = new OrbitControls(boxCamera, boxRenderer.domElement);
// orbit.enableDamping = true;
// orbit.dampingFactor = 0.1;

const hemiLight = new THREE.HemisphereLight(0xffffff, 0x080820, 3.5);

boxScene.add(hemiLight);

const directionalLight2 = new THREE.DirectionalLight(0xffffff, 3.5);
directionalLight2.position.set(5, 10, 7.5);
boxScene.add(directionalLight2);

const globeGroup = new THREE.Group();
boxScene.add(globeGroup);
const details = 120;
const geo = new THREE.IcosahedronGeometry(0, 0);

const mat = new THREE.MeshStandardMaterial({
  color: 0x202020,
  wireframe: true,
});
const cube = new THREE.Mesh(geo, mat);
globeGroup.add(cube);

const pointGeo = new THREE.IcosahedronGeometry(1, details);
const vertexShader = `
  uniform float size;
  uniform sampler2D elevTexture;

  varying vec2 vUv;
  varying float vVisible;

  void main() {
    vUv = uv;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    float elv = texture2D(elevTexture, vUv).r;
    vec3 vNormal = normalMatrix * normal;
    vVisible = step(0.0, dot( -normalize(mvPosition.xyz), normalize(vNormal)));
    mvPosition.z += 0.35 * elv;
    gl_PointSize = size;
    gl_Position = projectionMatrix * mvPosition;
  }
`;
const fragmentShader = `
  uniform sampler2D colorTexture;
  uniform sampler2D alphaTexture;

  varying vec2 vUv;
  varying float vVisible;

  void main() {
    // Discard fragment if the face is back-facing
    if (floor(vVisible + 0.1) == 0.0) discard;

    // Use alpha directly from the texture
    float alpha = 1.0 - texture2D(alphaTexture, vUv).r;
    vec3 color = texture2D(colorTexture, vUv).rgb;

    // Add a glow effect: let's use visibility to drive glow intensity
    float glowIntensity = 0.5; // Base glow intensity
    vec3 glowColor = vec3(1.0, 0.8, 0.2); // Yellowish glow color

    // Additive blending for glow
    vec3 finalColor = color + glowColor * glowIntensity * (1.0 - alpha);

    // Output the final color with alpha
    gl_FragColor = vec4(finalColor, alpha);
  }
`;
let texture1, texture2, texture3;
const loadingManager = new THREE.LoadingManager();

loadingManager.onLoad = function () {
  console.log("successful");

  const uniforms = {
    size: { type: "f", value: 4.0 },
    colorTexture: { type: "t", value: texture1 },
    elevTexture: { type: "t", value: texture2 },
    alphaTexture: { type: "t", value: texture3 },
    glowIntensity: { type: "f", value: 1.0 },
  };

  const pointsMat = new THREE.ShaderMaterial({
    uniforms: uniforms,
    vertexShader,
    fragmentShader,
    transparent: true,
  });

  // Create PointsMaterial with the loaded texture

  // Create points and add them to the group
  const points = new THREE.Points(pointGeo, pointsMat); // Use the defined pointGeo
  globeGroup.add(points);
};
const textureLoader = new THREE.TextureLoader(loadingManager);

textureLoader.load(
  textureURL.href,
  function (texture) {
    texture1 = texture;
  },
  undefined,
  function (error) {
    console.error("Error loading texture 1:", error);
  }
);

textureLoader.load(
  textureURL2.href,
  function (texture) {
    texture2 = texture;
  },
  undefined,
  function (error) {
    console.error("Error loading texture 2:", error);
  }
);

textureLoader.load(
  textureURL3.href,
  function (texture) {
    texture3 = texture;
  },
  undefined,
  function (error) {
    console.error("Error loading texture 3:", error);
  }
);

let isDragging = false;
let previousMousePosition = { x: 0, y: 0 };

// Mouse events
window.addEventListener("mousedown", function (e) {
  isDragging = true;
  previousMousePosition = { x: e.clientX, y: e.clientY };
});

window.addEventListener("mousemove", function (e) {
  if (!isDragging) return;

  // Calculate mouse movement
  const deltaMove = {
    x: e.clientX - previousMousePosition.x,
    y: e.clientY - previousMousePosition.y,
  };

  // Adjust the rotation speed for both axes
  const rotationSpeed = 0.005; // Adjust the speed of rotation

  // Update the horizontal rotation (left and right)
  globeGroup.rotation.y += deltaMove.x * rotationSpeed;

  // Update the vertical rotation (up and down), clamp it to prevent full rotation flips
  globeGroup.rotation.x += deltaMove.y * rotationSpeed;
  globeGroup.rotation.x = THREE.MathUtils.clamp(
    globeGroup.rotation.x,
    -Math.PI / 2,
    Math.PI / 2
  );

  // Update previous mouse position
  previousMousePosition = { x: e.clientX, y: e.clientY };
});

window.addEventListener("mouseup", function () {
  isDragging = false;
});

// Touch events
let isTouchDragging = false;
let previousTouchPosition = { x: 0, y: 0 };

// Touch start event (similar to mouse down)
window.addEventListener("touchstart", function (e) {
  isTouchDragging = true;
  previousTouchPosition = { x: e.touches[0].clientX, y: e.touches[0].clientY };
});

// Touch move event (similar to mouse move)
window.addEventListener("touchmove", function (e) {
  if (!isTouchDragging) return;

  // Calculate touch movement
  const deltaMove = {
    x: e.touches[0].clientX - previousTouchPosition.x,
    y: e.touches[0].clientY - previousTouchPosition.y,
  };

  // Adjust the rotation speed for both axes
  const rotationSpeed = 0.005; // Adjust the speed of rotation

  // Update the horizontal rotation (left and right)
  globeGroup.rotation.y += deltaMove.x * rotationSpeed;

  // Update the vertical rotation (up and down), clamp it to prevent full rotation flips
  globeGroup.rotation.x += deltaMove.y * rotationSpeed;
  globeGroup.rotation.x = THREE.MathUtils.clamp(
    globeGroup.rotation.x,
    -Math.PI / 2,
    Math.PI / 2
  );

  // Update previous touch position
  previousTouchPosition = { x: e.touches[0].clientX, y: e.touches[0].clientY };
});

// Touch end event (similar to mouse up)
window.addEventListener("touchend", function () {
  isTouchDragging = false;
});

////////////////////////////////////////////////////////////////////////////////////////////////////
//scene.add(line);

//assets
let logomodel;
let laddermodel;
const assetLoader = new GLTFLoader();
let head;
let rotar;
let body;
let robotmixer;
let robotmixer1;
let laddermixer;
let robots;
let vehicle;
let entityManager;
let robot1;
let robotClone;

function createCircularPathAroundLadder(center, radius, numPoints) {
  const path = new YUKA.Path();
  path.loop = true;

  for (let i = 0; i < numPoints; i++) {
    const angle = (i / numPoints) * Math.PI * 2;
    const x = center.x + radius * Math.cos(angle);
    const z = center.z + radius * Math.sin(angle);
    path.add(new YUKA.Vector3(x, center.y, z));
  }
  return path;
}
assetLoader.load(
  robot.href,
  function (gltf) {
    const model = gltf.scene;

    scene.add(model);
    robots = model;
    robotmixer = new THREE.AnimationMixer(model);
    const clips = gltf.animations;

    if (clips.length > 0) {
      clips.forEach(function (clip) {
        console.log(clip.name);
        const action = robotmixer.clipAction(clip);
        action.timeScale = 0.1;
        action.play();
      });
    }

    const robotClone = SkeletonUtils.clone(robots);
    //robotClone.position.set(0,0,0);
    robotClone.matrixAutoUpdate = false;
    scene.add(robotClone);
    //robot1 = robotClone;
    robotmixer1 = new THREE.AnimationMixer(robotClone);
    const clipss = gltf.animations;

    if (clipss.length > 0) {
      clipss.forEach(function (clip) {
        const action = robotmixer1.clipAction(clip);
        action.play();
      });
    }

    vehicle = new YUKA.Vehicle();
    vehicle.maxSpeed = 1;
    vehicle.setRenderComponent(robotClone, sync);

    model.position.set(3, -10, 111);
    head = model.getObjectByName("bottomneck");
    rotar = model.getObjectByName("rotar");
    body = model.getObjectByName("body");
  },
  undefined,
  function (error) {
    console.error(error);
  }
);

// assetLoader.load(logo.href, function(gltf) {

//     logomodel = gltf.scene;
//     scene.add(logomodel);

//     logomodel.position.set(123, 12, -28);

// }, undefined, function(error) {
//     console.error(error);
// });

assetLoader.load(
  ladder.href,
  function (gltf) {
    laddermodel = gltf.scene;
    scene.add(laddermodel);
    laddermixer = new THREE.AnimationMixer(laddermodel);
    const clips = gltf.animations;
    clips.forEach(function (clip) {
      const action = laddermixer.clipAction(clip);
      action.play();
    });

    laddermodel.position.set(-158, -42, -90);
    laddermodel.rotateY(Math.PI / 1.7);

    laddermodel.scale.set(2, 2, 2);

    const ladderPosition = { x: -80, y: -52, z: -120 };

    const circularPath = createCircularPathAroundLadder(
      ladderPosition,
      100,
      115
    );

    vehicle.position.copy(circularPath.current());

    const followPathBehaviour = new YUKA.FollowPathBehavior(circularPath, 0.5);
    vehicle.steering.add(followPathBehaviour);
    const onPathBehaviour = new YUKA.OnPathBehavior(circularPath);
    vehicle.steering.add(onPathBehaviour);
    entityManager = new YUKA.EntityManager();

    entityManager.add(vehicle);
  },
  undefined,
  function (error) {
    console.error(error);
  }
);

function sync(entity, renderComponent) {
  renderComponent.matrix.copy(entity.worldMatrix);
}

// const particlesGeometry = new THREE.BufferGeometry;
// const particlesCnt = 500;

// const posArray = new Float32Array(particlesCnt * 3);

// for(let i = 0; i < particlesCnt * 3; i++) {

//     posArray[i] = (Math.random() - 0.3) * 130;
// };

// particlesGeometry.setAttribute('position', new THREE.BufferAttribute(posArray, 3));

// const material = new THREE.PointsMaterial(
//     {
//         size: 0.005,

//         transparent: true
//     }
// );

// const partilesMesh = new THREE.Points(particlesGeometry, material);

// //scene.add( partilesMesh);
// partilesMesh.position.set(0, 5, 0);

const target = new THREE.Object3D();
target.position.z = 2;
const mouse = new THREE.Vector2();

const intersectionPoint = new THREE.Vector3();
const planeNormal = new THREE.Vector3();
const plane = new THREE.Plane();
const raycaster = new THREE.Raycaster();

window.addEventListener("mousemove", function (e) {
  mouse.x = (e.clientX / app.clientWidth) * 2 - 1;
  mouse.y = -(e.clientY / app.clientHeight) * 2 + 1.4;
  planeNormal.copy(camera.position).normalize();
  plane.setFromNormalAndCoplanarPoint(planeNormal, scene.position);
  raycaster.setFromCamera(mouse, camera);
  raycaster.ray.intersectPlane(plane, intersectionPoint);
  target.position.set(intersectionPoint.x, intersectionPoint.y, 2);
});

console.log("can you hear me sucker");

function animate() {
  const delta = clock.getDelta();
  const deltt = time.update().getDelta() * 10.0;
  console.log(updateCount);
  updateCount++;

  if (entityManager) {
    entityManager.update(deltt);
    console.log(vehicle.position);
  }

  if (laddermixer) {
    laddermixer.update(delta);
  }

  if (robotmixer) {
    console.log("Updating robot mixer");
    robotmixer.update(delta);
  }
  if (robotmixer1) {
    robotmixer1.update(delta);
  }

  if (head) {
    const targetRotationY = (target.position.x - head.position.x) * 0.1;
    head.rotation.y = THREE.MathUtils.lerp(
      head.rotation.y,
      targetRotationY,
      0.1
    );
    head.rotation.y = THREE.MathUtils.clamp(
      head.rotation.y,
      -Math.PI / 2.3,
      Math.PI / 2.3
    );
  }

  if (rotar) {
    const targetRotationX = (target.position.y - rotar.position.y) * 0.1;
    rotar.rotation.x = THREE.MathUtils.lerp(
      rotar.rotation.x,
      -targetRotationX,
      0.1
    );
    rotar.rotation.x = THREE.MathUtils.clamp(
      rotar.rotation.x,
      -Math.PI / 2,
      Math.PI / -9
    );
  }

  if (body) {
    const targetRotationY = (target.position.x - body.position.x) * 0.2;
    body.rotation.y = THREE.MathUtils.lerp(
      head.rotation.y,
      targetRotationY,
      0.2
    );
    body.rotation.y = THREE.MathUtils.clamp(
      head.rotation.y,
      -Math.PI / 2.3,
      Math.PI / 2.3
    );
  }

  if (robotClone) {
    robotClone.updateMatrixWorld(true); // Update the matrix after any transformations
  }

  //orbit.update();
  renderer.render(scene, camera);
  globeGroup.rotation.y += 0.001;
  // Rotate the globe for some effect

  boxRenderer.render(boxScene, boxCamera);
  //orbit.update();
  requestAnimationFrame(animate); // Continue the animation loop
}

window.addEventListener("resize", () => {
  // Update renderer size
  renderer.setSize(app.offsetWidth, app.offsetHeight);

  // Update camera aspect ratio and projection matrix
  camera.aspect = app.offsetWidth / app.offsetHeight;
  camera.updateProjectionMatrix();

  boxRenderer.setSize(boxContainer.offsetWidth, boxContainer.offsetHeight);
  boxCamera.aspect = boxContainer.offsetWidth / boxContainer.offsetHeight;
  boxCamera.updateProjectionMatrix();
});

animate(); // Start the animation loop

const baseText = "We offer services in  ";
const texts = [
  "IOT",
  "AI/ML",
  "Cloud-Computing",
  "SOFTWARE-DEVELOPMENT",
  "Data-Science",
  "Training & Development",
];
let index = 0;
let textIndex = 0;
let baseIndex = 0;
const span = document.getElementById("animated");
const delay = 100; // Delay between each character
const changeDelay = 2000; // Delay before changing to the next text

function typeWriter() {
  if (baseIndex < baseText.length) {
    span.textContent = baseText.substring(0, baseIndex + 1);
    baseIndex++;
    setTimeout(typeWriter, delay); // Smooth delay between characters
  } else if (textIndex < texts[index].length) {
    span.textContent = baseText + texts[index].substring(0, textIndex + 1);
    textIndex++;
    setTimeout(typeWriter, delay); // Delay between characters
  } else {
    setTimeout(() => {
      textIndex = 0;
      baseIndex = 0;
      index = (index + 1) % texts.length; // Loop to next service
      typeWriter();
    }, changeDelay); // Delay before switching to the next text
  }
}

// Start the typewriter effect
typeWriter();
const dots = document.querySelectorAll(".dots");
const cards = document.querySelectorAll(".cards > div"); // Get all card elements
const serviceLogs = document.querySelector(".service-logs");
let activeDot = null;

// Function to hide all cards and display the active one
const displayCard = (serviceId) => {
  cards.forEach((card) => {
    const isActive = card.getAttribute("data-service") === serviceId;
    card.style.display = isActive ? "block" : "none"; // Show only the matching card
  });
};

// Show the IoT card (default card) on window load
window.onload = () => {
  displayCard("1"); // Display IoT card (service ID 1)
};

// Handle dot clicks
dots.forEach((dot) => {
  dot.addEventListener("click", (e) => {
    e.preventDefault(); // Prevent default behavior
    const serviceId = dot.getAttribute("data-service");

    // Deactivate previous dot
    if (activeDot && activeDot !== dot) {
      activeDot.classList.remove("dotted"); // Reset the previous dot
    }

    // Activate the clicked dot
    if (activeDot !== dot) {
      dot.classList.add("dotted"); // Highlight the current dot
      serviceLogs.style.gap = "10px"; // Increase the gap
      displayCard(serviceId); // Show the corresponding card
      activeDot = dot; // Set the current dot as active
    } else {
      // If the same dot is clicked, reset to default
      dot.classList.remove("dotted");
      displayCard("1"); // Show the default card
      serviceLogs.style.gap = "10px"; // Reset the gap
      activeDot = null; // Clear active dot
    }
  });
});

// Handle clicks outside the dots
document.addEventListener("click", (e) => {
  if (!e.target.closest(".dots") && activeDot) {
    activeDot.classList.remove("dotted"); // Reset the dot style
    displayCard("1"); // Reset to the default card
    serviceLogs.style.gap = "10px"; // Reset the gap
    activeDot = null; // Clear active dot
  }
});

document.addEventListener("DOMContentLoaded", () => {
  const hoursElement = document.getElementById("hour-hand");
  const minutesElement = document.getElementById("minute-hand");
  const secondsElement = document.getElementById("second-hand");

  // Check if elements are null

  if (!hoursElement || !minutesElement || !secondsElement) {
    console.error("One or more clock elements are not found");
    return; // Stop if elements aren't found
  }
  console.log("Elements:", hoursElement, minutesElement, secondsElement);

  function animats() {
    const date = new Date();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    hoursElement.setAttribute(
      "transform",
      `rotate(${(360 / 12) * (hour % 12)})`
    );
    minutesElement.setAttribute("transform", `rotate(${(360 / 60) * minute})`);
    secondsElement.setAttribute("transform", `rotate(${(360 / 60) * second})`);
    console.log(hoursElement, minutesElement, secondsElement);
    requestAnimationFrame(animats);
  }

  requestAnimationFrame(animats);
});

gsap.registerPlugin(ScrollTrigger);

const show = document.querySelector(".slideshowss");

gsap.utils.toArray(".arrrow").forEach((arrow) => {
  const a1 = gsap.timeline({
    scrollTrigger: {
      trigger: show,
      start: "top bottom",
      end: "bottom top",
      scrub: true,
    },
  });

  a1.fromTo(
    arrow.querySelectorAll("line"),
    {
      strokeDasharray: 0, // Starting with no visible stroke
      strokeDashoffset: 0, // Start at the beginning
    },
    {
      strokeDasharray: 100, // Length of the visible stroke
      strokeDashoffset: 100, // Move the stroke across the path
      duration: 5, // Adjust duration to control speed
      // Infinite repetition
      yoyo: true, // Go back and forth to create shrinking/expanding effect
      ease: "power1.inOut",
    }
  );
});

let industries = document.querySelectorAll(".industry");
let industries1 = document.querySelectorAll(".industry1");

industries.forEach((industry) => {
  industry.addEventListener("mousedown", function () {
    // Remove 'active' class from all .industry1 elements
    industries1.forEach((ind) => {
      ind.classList.remove("active");
    });

    // Remove 'active' class from all .industry elements
    industries.forEach((ind) => {
      ind.classList.remove("active");
    });

    // Add 'active' class to the clicked .industry
    this.classList.add("active");

    // Add 'active' class to the corresponding .industry1
    let correspondingContent = document.getElementById(this.dataset.id);
    if (correspondingContent) {
      correspondingContent.classList.add("active");
    }
  });
});

const socket = new WebSocket("ws://localhost:3000");

// Handle socket events
socket.addEventListener("open", (event) => {
  console.log("WebSocket connection established");
});

socket.addEventListener("message", (event) => {
  console.log("Message from server:", event.data);
});

socket.addEventListener("error", (error) => {
  console.error("WebSocket error:", error);
});

// Variable to store the timeout ID for debounce
// let resizeTimeout;

// // Add an event listener for the window resize event
// window.addEventListener("resize", () => {
//   // Clear any previous timeout if resize continues happening
//   clearTimeout(resizeTimeout);

//   // Set a new timeout to refresh the page after 500ms of no resizing
//   resizeTimeout = setTimeout(() => {
//     window.location.reload(); // Refresh the page when resize stops for 500ms
//   }, 500);
// });
